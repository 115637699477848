import { Card, Col, Image, Layout, Row, Typography } from "antd";
import React from "react";
import student from "../../assets/images/yec-students.jpg";
import teacher from "../../assets/images/book-with-green-board-background.jpg";
import parents from "../../assets/images/yec-responsible.jpg";

export function JoinHome() {
  return (
    <Layout className="yc-container">
      <Typography.Title level={2} style={{ textAlign: "center" }}>
        {`Avec un abonnement annuel de 1$, bénéficiez de tous nos services`}
      </Typography.Title>
      <Row
        gutter={8}
        align="stretch"
        style={{ padding: "5px 20px", maxWidth: "100%", alignSelf: "center" }}
      >
        <Col
          xs={24}
          md={8}
          style={{ marginTop: 20, minHeight: 300 }}
          className="yc-join-img"
        >
          <Card className="yc-card" style={{ height: "auto" }}>
            <Image preview={false} src={student} className="yc-join-img" />
            <Typography.Title level={3} className="yc-text-h3">
              {`Elèves et étudiants`}
            </Typography.Title>
            <Typography.Paragraph className="yc-p-title">
              {`Contribuez à votre réussite et faites le suivi de votre impact`}
            </Typography.Paragraph>
            <Typography.Paragraph>
              {`Faites part de votre travail à d'autres élèves et étudiants. Renforcez votre auditoire et surveillez votre impact sur votre domaine grâce à nos analyses fiables.`}
            </Typography.Paragraph>
          </Card>
        </Col>
        <Col xs={24} md={8} style={{ marginTop: 20 }} className="yc-join-img">
          <Card className="yc-card" style={{ height: "auto" }}>
            <Image preview={false} src={teacher} />
            <Typography.Title level={3} className="yc-text-h3">
              {`Enseignants`}
            </Typography.Title>
            <Typography.Paragraph className="yc-p-title">
              {`Simplifiez la decouverte des recherches pertinentes. Preparez-vous à mieux préparer vos leçons`}
            </Typography.Paragraph>
            <Typography.Paragraph>
              {`Avec notre bibliothèque virtuelle yeccothèque,
consultez nos manuels scolaires, nos
prévisions de matières uniformisées ECCATH et
bénéficiez du transfert des connaissances`}
            </Typography.Paragraph>
          </Card>
        </Col>
        <Col xs={24} md={8} style={{ marginTop: 20 }} className="yc-join-img">
          <Card className="yc-card" style={{ height: "auto" }}>
            <Image preview={false} src={parents} />
            <Typography.Title level={3} className="yc-text-h3">
              {`Parents`}
            </Typography.Title>
            <Typography.Paragraph className="yc-p-title">
              {`Apprenez à découvrir l'intelligence
créative de vos enfants et à les
accompagner sur la voie du succès
(métiers du futur)`}
            </Typography.Paragraph>
            <Typography.Paragraph>
              {`Formation et sensibilisation continue aux
technologies de l'information et de la
communication, à la promotion de l'esprit
entrepreneurial, aux métiers du futurs (STEM -
Orientation scolaire)`}
            </Typography.Paragraph>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
}
