import { FilePdfOutlined, StarOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Card, Col, Layout, Row, Typography } from "antd";
import React from "react";
import { ItemCard } from "../components/item";
import { AppHome } from "./root/app";
import { FooterHome } from "./root/footer";
import { JoinHome } from "./root/join";
import { NavbarHome } from "./root/navbar";
import { ReadHome } from "./root/read";
import { SecondaryHome } from "./root/secondary";
import poster from "../assets/images/baby.png";
import { setAppTitle } from "../utils/helpers/title";
import userHelper from "../utils/helpers/userHelper";

export function RootHome() {
  setAppTitle("Bienvenue");
  const [loading, setLoading] = React.useState(false);

  async function init() {
    setLoading(true);
    const isRefreshed = await userHelper.refreshUser();
    if (isRefreshed) {
      location.href = "/admin/";
    }
    setLoading(false);
  }
  function scrollFunction() {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      document.getElementById("home-navbar").classList.add("yc-navbar-shadow");
    } else {
      document
        .getElementById("home-navbar")
        .classList.remove("yc-navbar-shadow");
    }
  }
  window.onscroll = function () {
    scrollFunction();
  };
  return (
    <Layout.Content style={{ overflowX: "hidden" }}>
      <NavbarHome />
      <Row gutter={8} style={{ marginTop: 20 }} className="yc-container">
        <Col xs={24} md={10} style={{ margin: "10px 0" }}>
          <Typography.Title style={{ fontSize: 30 }}>
            {
              "Accédez aux contenus pédagogiques de qualité, modernes et standardisés pour élèves, étudiants, enseignants et parents d’élèves. (ODD-4)"
            }
          </Typography.Title>
          {!userHelper.isConnected() ? (
            <Button className="yc-btn" type="primary">
              <a href="/home/register">{"S'inscrire"}</a>
            </Button>
          ) : (
            <Button
              loading={loading}
              disabled={loading}
              onClick={init}
              type="primary"
            >
              {"Se lancer"}
            </Button>
          )}
        </Col>
        <Col md={14} xs={24} style={{ margin: "10px 0" }}>
          <img src={poster} className="yc-student-desktop" />
          <div className="yc-root-card">
            <Card
              className="yc-card"
              bodyStyle={{ padding: 8 }}
              style={{ maxWidth: 300, padding: 0, borderRadius: 0 }}
            >
              <ItemCard
                color="#801452"
                title="Des millions d'utilisateurs"
                count={0}
                Icon={UserOutlined}
              />
              <ItemCard
                title="Leçons (audios, vidéos et textes)"
                color="#750011"
                count={100000}
                Icon={StarOutlined}
              />
              <ItemCard
                color="#770077"
                title="Plusieurs unités pédagogiques"
                count={0}
                Icon={FilePdfOutlined}
                bordered={false}
              />
            </Card>
          </div>
        </Col>
      </Row>
      <SecondaryHome />
      <JoinHome />
      <AppHome />
      <ReadHome />
      <FooterHome />
    </Layout.Content>
  );
}
